// Generated by Framer (461de17)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Image, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/kNNKW3bXk";

const enabledGestures = {U82OJ3iyb: {hover: true}};

const cycleOrder = ["U82OJ3iyb"];

const serializationHash = "framer-2pNfR"

const variantClassNames = {U82OJ3iyb: "framer-v-18e8wv3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, link, title, width, ...props}) => { return {...props, KDaBa9tQh: title ?? props.KDaBa9tQh ?? "For buyers", yNr5JyU2_: image ?? props.yNr5JyU2_ ?? {src: "https://framerusercontent.com/images/1POtQUgPxnyZHK6RHNABYyxxsIM.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/1POtQUgPxnyZHK6RHNABYyxxsIM.png?scale-down-to=1024 760w,https://framerusercontent.com/images/1POtQUgPxnyZHK6RHNABYyxxsIM.png?scale-down-to=2048 1520w,https://framerusercontent.com/images/1POtQUgPxnyZHK6RHNABYyxxsIM.png 2296w"}, zuA5OWtPU: link ?? props.zuA5OWtPU} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;image?: {src: string; srcSet?: string};link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, KDaBa9tQh, yNr5JyU2_, zuA5OWtPU, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "U82OJ3iyb", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={zuA5OWtPU} openInNewTab={false} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-18e8wv3", className, classNames)} framer-1bfsnxi`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"U82OJ3iyb"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-afc13746-dfe9-4cb3-8e99-c6882b9bc11b, rgb(11, 25, 45))", ...style}} {...addPropertyOverrides({"U82OJ3iyb-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(yNr5JyU2_)}} className={"framer-1o5h9b1"} layoutDependency={layoutDependency} layoutId={"WALPjM9aW"} style={{opacity: 1}} variants={{"U82OJ3iyb-hover": {opacity: 0.75}}} {...addPropertyOverrides({"U82OJ3iyb-hover": {background: {alt: "", fit: "fill", sizes: `calc(${componentViewport?.width || "100vw"} * 1.1)`, ...toResponsiveImage(yNr5JyU2_)}}}, baseVariant, gestureVariant)}/><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 className={"framer-styles-preset-fr3oqa"} data-styles-preset={"kNNKW3bXk"} style={{"--framer-text-color": "var(--extracted-1of0zx5, var(--token-f69ad479-b769-401f-81fa-39e0b107e2e7, rgb(255, 255, 255)))"}}>For buyers</motion.h2></React.Fragment>} className={"framer-mvw8r8"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"FGt4q7YOG"} style={{"--extracted-1of0zx5": "var(--token-f69ad479-b769-401f-81fa-39e0b107e2e7, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={KDaBa9tQh} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2pNfR.framer-1bfsnxi, .framer-2pNfR .framer-1bfsnxi { display: block; }", ".framer-2pNfR.framer-18e8wv3 { align-content: flex-end; align-items: flex-end; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 400px; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 600px; }", ".framer-2pNfR .framer-1o5h9b1 { flex: none; height: 100%; left: calc(50.00000000000002% - 100% / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 100% / 2); width: 100%; z-index: 1; }", ".framer-2pNfR .framer-mvw8r8 { bottom: 24px; flex: none; height: auto; left: 24px; position: absolute; white-space: pre; width: auto; z-index: 2; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-2pNfR.framer-18e8wv3 { gap: 0px; } .framer-2pNfR.framer-18e8wv3 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-2pNfR.framer-18e8wv3 > :first-child { margin-left: 0px; } .framer-2pNfR.framer-18e8wv3 > :last-child { margin-right: 0px; } }", ".framer-2pNfR.framer-v-18e8wv3.hover .framer-1o5h9b1 { height: 110%; left: calc(50.00000000000002% - 110.00000000000001% / 2); top: calc(50.00000000000002% - 110.00000000000001% / 2); width: 110%; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 400
 * @framerIntrinsicWidth 600
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"LNovz0WnH":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"KDaBa9tQh":"title","yNr5JyU2_":"image","zuA5OWtPU":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCx12qUTsg: React.ComponentType<Props> = withCSS(Component, css, "framer-2pNfR") as typeof Component;
export default FramerCx12qUTsg;

FramerCx12qUTsg.displayName = "resourcesCard";

FramerCx12qUTsg.defaultProps = {height: 400, width: 600};

addPropertyControls(FramerCx12qUTsg, {KDaBa9tQh: {defaultValue: "For buyers", displayTextArea: false, title: "Title", type: ControlType.String}, yNr5JyU2_: {__defaultAssetReference: "data:framer/asset-reference,1POtQUgPxnyZHK6RHNABYyxxsIM.png?originalFilename=img.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, zuA5OWtPU: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerCx12qUTsg, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})